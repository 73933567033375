import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

import { Helmet } from 'react-helmet';

const SchemaOrg = () => {
	const schema = {
	  "@context": "https://schema.org",
	  "@type": "WebPage",
	  "name": "Content Marketing Webinars",
	  "url": "https://websuasion.com/content-marketing-webinars/",
	  "description": "Explore Websuasion's content marketing webinars, offering insights into effective content strategies, SEO, and more.",
	  "publisher": {
	    "@type": "Organization",
	    "name": "The Websuasion Group LLC",
	    "logo": {
	      "@type": "ImageObject",
	      "url": "https://websuasion.com/images/websuasion-full-logo.png"
	    }
	  },
	  "mainEntity": {
	    "@type": "ItemList",
	    "itemListElement": [
	      {
	        "@type": "Event",
	        "name": "How Robust Keyword Research Can Transform Your Clients' Marketing Efforts",
	        "description": "This webinar goes into the transformative power of robust keyword research in enhancing marketing strategies for clients.",
	        "url": "https://websuasion.easywebinar.live/white-label-keyword-research-registration",
	        "location": {
	          "@type": "VirtualLocation",
	          "url": "https://websuasion.easywebinar.live/white-label-keyword-research-registration"
	        },
	        "organizer": {
	          "@type": "Organization",
	          "name": "The Websuasion Group LLC",
	          "url": "https://websuasion.com"
	        }
	      },
	      {
	        "@type": "Event",
	        "name": "Leveraging Fractional Marketing For Scalability and Savings",
	        "description": "This webinar explores the concept of fractional marketing and its benefits for scaling marketing efforts and controlling budgets.",
	        "url": "https://websuasion.easywebinar.live/fractional-marketing-registration",
	        "location": {
	          "@type": "VirtualLocation",
	          "url": "https://websuasion.easywebinar.live/fractional-marketing-registration"
	        },
	        "organizer": {
	          "@type": "Organization",
	          "name": "The Websuasion Group LLC",
	          "url": "https://websuasion.com"
	        }
	      },
	      {
	        "@type": "Event",
	        "name": "Growing Your Marketing Firm With White Label Services",
	        "description": "This webinar focuses on how marketing firms can leverage white label services to scale their operations and attract larger clients.",
	        "url": "https://websuasion.easywebinar.live/white-label-services-registration",
	        "location": {
	          "@type": "VirtualLocation",
	          "url": "https://websuasion.easywebinar.live/white-label-services-registration"
	        },
	        "organizer": {
	          "@type": "Organization",
	          "name": "The Websuasion Group LLC",
	          "url": "https://websuasion.com"
	        }
	      }
	    ]
	  }
	};
	
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};


const IndexPage = () => {
  return (
    <PageLayout>
      <SchemaOrg />
			<Seo
        title="Content Marketing Webinars - Fractional and White Label"
        ogDescription="Explore Websuasion's free content marketing webinars, offering insights into effective content strategies, SEO, and more."
        image="/images/content-marketing-webinars.webp"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Content Marketing Webinars</h1>
						<h3>Learn about fractional and white label marketing with <span className="websuasion">websuasion</span></h3>
            <p>
							Learn how to grow your business or marketing firm's digital marketing capabilities with fractional and white label marketing. The webinars below are led by J. Ryan Williams, the President of websuasion. Each session offers deep dives into essential strategies like keyword research, fractional marketing, and white label services, tailored to enhance and scale your marketing efforts efficiently.
						</p>
						<p>
							With Ryan's 25 years of experience, these webinars are designed to equip you with the knowledge to refine your marketing tactics, adapt to industry trends, and achieve sustainable growth. Whether you're looking to optimize your SEO approach, expand your service offerings, or streamline your operations, our webinars provide actionable insights and strategic guidance to help you thrive.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Schedule An Introductory Call",
                size: "tiny",
                icon: "calendar alternate outline",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img src="/images/content-marketing-webinars.webp" className="rounded-image" alt="Content Marketing Webinars - White Label Marketing - Fractional CMO - Websuasion" />
          </div>
        }
      />
	    <PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
	            <img src="/images/webinar-keyword-research.webp" className="rounded-image" alt="Keyword Research Webinar - Websuasion" />
	          </div>
	        }
	        textRight={
	          <div>
			        <h2>How Robust Keyword Research Can Transform Your Clients' Marketing Efforts</h2>
			        <p>
								This webinar, led by J. Ryan Williams, President of Websuasion, goes into the transformative power of robust keyword research in enhancing marketing strategies for clients. With 25 years of experience in digital marketing and SEO, Ryan highlights the technical aspects and strategic significance of keyword research. The session covers the basics of keywords, the pitfalls of prioritizing them too late in the marketing process, and the benefits of starting with keyword research to align branding and messaging effectively.
							</p>
							<p>
								Ryan explains how keyword research helps understand the user's intent across different search types—informational, navigational, transactional, and commercial investigation. He illustrates how such research feeds into creating content that aligns with various stages of the buyer's journey—awareness, interest, consideration, conversion, and retention—effectively guiding potential customers from initial interest to loyal advocacy.
							</p>
							<p>
								Furthermore, the webinar addresses how keyword research informs not only SEO and content creation but also PPC campaigns, social media marketing, and overall business strategy. Ryan underscores the necessity of integrating keyword insights from the onset to ensure marketing efforts are as targeted and efficient as possible, urging participants to consider keyword research as a foundational step in any marketing plan.
			        </p>
			        <p>
			          <QuickLinkButton
			            content={"Register Now"}
			            url={"https://websuasion.easywebinar.live/white-label-keyword-research-registration"}
			            icon={"calendar"}
			          />
			        </p>
	          </div>
	        }
	      />
	    </PageWrapper>
	    <PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
	            <img src="/images/webinar-fractional-marketing.webp" className="rounded-image" alt="Fractional Marketing Webinar - Websuasion" />
	          </div>
	        }
	        textRight={
	          <div>
	            <h2>Leveraging Fractional Marketing For Scalability and Savings</h2>
	            <p>
								This webinar, hosted by J. Ryan Williams, President of Websuasion, explores the concept of fractional marketing and its benefits for scaling marketing efforts and controlling budgets. Ryan, with 25 years of experience in marketing, web development, and content production, introduces fractional marketing as a strategy where businesses hire skilled professionals on a part-time basis, providing flexibility and cost efficiency.
							</p>
							<p>
								The session is divided into three segments, starting with an overview of fractional marketing, followed by detailed discussions on the specific services provided, and concluding with strategies for effectively implementing fractional marketing into daily business operations. Ryan highlights the advantages of this model, such as access to diverse expertise, scalability, and the ability to focus on core business functions without the overheads associated with full-time staff. He also addresses potential drawbacks like less control over marketing processes and the challenges of integration with existing teams.
							</p>
							<p>
								Throughout the webinar, Ryan emphasizes the strategic importance of services such as keyword research, content strategy, SEO, web design, and PPC management, explaining how they are adapted to the fractional marketing model to enhance efficiency and effectiveness. The presentation concludes with a call to action, inviting attendees to a personalized consultation to explore how fractional marketing can benefit their specific business needs.
	            </p>
			        <p>
			          <QuickLinkButton
			            content={"Register Now"}
			            url={"https://websuasion.easywebinar.live/fractional-marketing-registration"}
			            icon={"calendar"}
			          />
			        </p>
	          </div>
	        }
	      />
	    </PageWrapper>
      <PageWrapper color="charcole">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
	            <img src="/images/webinar-white-label-marketing.webp" className="rounded-image" alt="White-Label Marketing Webinar - Websuasion" />
	          </div>
	        }
	        textRight={
	          <div>
              <h2>Growing Your Marketing Firm With White Label Services</h2>
              <p>
								This webinar, presented by J. Ryan Williams, President of Websuasion, focuses on how marketing firms can leverage white label services to scale their operations and attract larger clients. With over 25 years of experience in marketing, web development, and content production, Ryan explains the concept of white label marketing—where firms outsource digital marketing services such as SEO, PPC, web design, and social media management, and then rebrand these services as their own.
							</p>
							<p>
								The webinar is structured into three parts: an introduction to white label marketing, a detailed look at the specific services provided, and guidance on effectively integrating these services into a firm’s daily activities. Ryan discusses the advantages of white label marketing, such as cost-effectiveness, access to specialized skills, and the ability to focus on core business strengths. However, he also addresses potential drawbacks like less control over the marketing process and the risk of service inconsistency.
							</p>
							<p>
								Throughout the session, Ryan emphasizes how white label services can provide flexibility and scalability, allowing marketing firms to manage growth effectively without the overhead associated with hiring full-time specialists. He concludes with a call to action, encouraging attendees to consider a partnership to expand their service offerings and enhance their market position.
              </p>
			        <p>
			          <QuickLinkButton
			            content={"Register Now"}
			            url={"https://websuasion.easywebinar.live/white-label-services-registration"}
			            icon={"calendar"}
			          />
			        </p>
	          </div>
	        }
	      />
      </PageWrapper>
			<PageWrapper color="white">					
				<TextBlock
	        textPadded
	        textLeft={
	          <div>
				      <div className='player-wrapper'>
				        <ReactPlayer
				          className='react-player'
				          url='https://www.youtube.com/watch?v=-YTR647HTBM'
				          width='100%'
				          height='100%'
				        />
							</div>
	          </div>
	        }
	        textRight={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>Websuasion combines data analytics, cutting-edge tech, and expert teams. "Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	          </div>
	        }
	      />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
